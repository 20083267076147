<template>
  <Container size="small">
    <Breadcrumbs :items="pageBreadcrumbs" />
  </Container>
  <component
    :is="componentName(element, components)"
    v-for="element in page.elements"
    :key="element.id"
    :class="NARROW_ELEMENTS.includes(element.name) && 'narrow'"
    :element="element"
    data-template="learning-guide"
  />
</template>

<script lang="ts" setup>
  import { defineAsyncComponent } from "vue"
  import { useNotificationStore } from "~/pinia/notifications"

  const NARROW_ELEMENTS = [
    "text_block",
    "fragrance_teaser",
    "headline",
    "glossary",
    "documents_list",
    "testimonial",
    "pinterest_gallery",
    "table_of_contents",
  ]

  const props = defineProps<{
    page: AlchemyPage
  }>()

  const pageBreadcrumbs = usePageBreadcrumbs(props.page)

  const nStore = useNotificationStore()
  const { componentName } = useAlchemy()
  const config = useRuntimeConfig()

  useHead(usePageInfo(props.page))
  useHead({
    script: [
      {
        type: "application/ld+json",
        innerHTML: {
          "@context": "https://schema.org",
          "@type": "Article",
          name: `CandleScience - ${props.page.name}`,
          url: `${config.public.BASE_URL}${props.page.urlPath}`,
        },
      },
    ],
  })

  onMounted(() => {
    nStore.announce(props.page.title)
  })

  const components = {
    cards: defineAsyncComponent(() => import("~/alchemy/elements/cards.vue")),
    disqus_embed: defineAsyncComponent(
      () => import("~/alchemy/elements/disqus_embed.vue"),
    ),
    divider: defineAsyncComponent(
      () => import("~/components/Divider/index.vue"),
    ),
    documents_list: defineAsyncComponent(
      () => import("~/alchemy/elements/documents_list.vue"),
    ),
    fragrance_teaser: defineAsyncComponent(
      () => import("~/alchemy/elements/fragrance_teaser.vue"),
    ),
    gallery: defineAsyncComponent(
      () => import("~/alchemy/elements/gallery.vue"),
    ),
    glossary: defineAsyncComponent(
      () => import("~/alchemy/elements/glossary.vue"),
    ),
    headline: defineAsyncComponent(
      () => import("~/alchemy/elements/headline.vue"),
    ),
    image_full_width: defineAsyncComponent(
      () => import("~/alchemy/elements/image_full_width.vue"),
    ),
    masthead: defineAsyncComponent(
      () => import("~/alchemy/elements/masthead.vue"),
    ),
    pinterest_gallery: defineAsyncComponent(
      () => import("~/alchemy/elements/pinterest_gallery.vue"),
    ),
    pro_tip: defineAsyncComponent(
      () => import("~/alchemy/elements/pro_tip.vue"),
    ),
    product_slider: defineAsyncComponent(
      () => import("~/alchemy/elements/product_slider.vue"),
    ),
    project_items: defineAsyncComponent(
      () => import("~/alchemy/elements/project_items.vue"),
    ),
    section_with_background: defineAsyncComponent(
      () => import("~/alchemy/elements/section_with_background.vue"),
    ),
    story_banner: defineAsyncComponent(
      () => import("~/alchemy/elements/story_banner.vue"),
    ),
    table_of_contents: defineAsyncComponent(
      () => import("~/components/TableOfContents/index.vue"),
    ),
    testimonial: defineAsyncComponent(
      () => import("~/alchemy/elements/testimonial.vue"),
    ),
    testimonials: defineAsyncComponent(
      () => import("~/alchemy/elements/testimonials/index.vue"),
    ),
    text_block: defineAsyncComponent(
      () => import("~/alchemy/elements/text_block.vue"),
    ),
    text_image_left: defineAsyncComponent(
      () => import("~/alchemy/elements/text_image_left.vue"),
    ),
    text_image_right: defineAsyncComponent(
      () => import("~/alchemy/elements/text_image_right.vue"),
    ),
    grid_columns: defineAsyncComponent(
      () => import("~/alchemy/elements/grid_columns.vue"),
    ),
    wick_guide: defineAsyncComponent(
      () => import("~/alchemy/elements/wick_guide.vue"),
    ),
    wistia_embed: defineAsyncComponent(
      () => import("~/alchemy/elements/wistia_embed.vue"),
    ),
  }
</script>

<style lang="scss" scoped>
  .narrow {
    max-width: $content-width-small;
    margin: 0 auto;
  }
</style>
